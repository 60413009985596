<script setup lang="ts">
import { NConfigProvider, darkTheme, lightTheme, NDialogProvider, NMessageProvider } from 'naive-ui';
import { NPopupProvider } from 'naive-tools';
import 'naive-tools/style.css';
import { CookieBanner, type CookieSettings } from '@bluepic/privacy';
import '@bluepic/privacy/style.css';
import { cookieSettings } from './main';

console.log(window.location);

const { fetchingConfig, config } = useConfig();
// @ts-ignore
window.config = () => {
  if (fetchingConfig.value) {
    return 'fetching config';
  }
  // @ts-ignore
  return Object.fromEntries(Object.entries(config.value));
};

// const cookieConsent = ref();
// watch(
//   cookieConsent,
//   (nv) => {
//     if (nv === false) {
//       cookieConsent.value = undefined;
//       window.location.href = 'https://bluepic.io';
//     } else if (nv === true) {
//       window.localStorage.setItem('cookieConsent', 'true');
//     }
//   },
//   { immediate: true }
// );
const themeStore = useThemeStore();
themeStore.init();
const savedFontSize = useLocalStorage('fontSize', '8px');
watch(
  savedFontSize,
  (nv) => {
    if (nv) {
      useCssVar('--root-font-size').value = nv;
    }
  },
  { immediate: true }
);
const tDuration = useMotion();
watch(
  tDuration,
  (nv) => {
    if (nv) {
      useCssVar('--t-dur').value = `${nv}ms`;
    }
  },
  { immediate: true }
);
const showCookieBanner = ref(false);

const shortLocale = computed(() => {
  //only give first part of localestring
  return locale.value.split('-')[0];
});
</script>

<template>
  <div class="index">
    <n-config-provider :theme="lightTheme" :theme-overrides="themeStore.themeOverrides().value">
      <n-dialog-provider>
        <n-message-provider>
          <b-overlay-provider>
            <n-popup-provider>
              <div class="page-wrapper">
                <router-view v-slot="{ Component, route }">
                  <cookie-banner
                    v-model:show="showCookieBanner"
                    @update:cookie-settings="cookieSettings = $event"
                    :locale="(shortLocale as any)"
                    darkmode
                  />
                  <transition name="page" appear @after-enter="(route.meta as any).afterEnter?.()">
                    <component :is="Component" />
                  </transition>
                </router-view>
              </div>
            </n-popup-provider>
          </b-overlay-provider>
        </n-message-provider>
      </n-dialog-provider>
    </n-config-provider>
  </div>
</template>

<style lang="scss">
#app {
  @apply h-full overflow-hidden p-0 m-0;
}
</style>

<style scoped lang="scss">
.index {
  @apply relative h-full w-full font-normal text-base;
  :deep().n-popup-provider {
    @apply h-full;
    .app-wrapper {
      @apply h-full;
    }
    .popups-wrapper {
      @apply z-[10];
    }
  }
  .n-popup__root {
    @apply h-0;
  }
  .page-wrapper {
    background-color: rgba(255, 255, 255, 1);
    @apply relative w-full h-full overflow-hidden;
  }
}
</style>
