import { UserOutput } from '@bluepic/types/src/Auth/user.output';
import { logout, onLogout } from '../util/logout';
import { imgChange } from '../util/imgChange';
import { BaseActions, pinia } from './pinia';
import { router } from '../routes';
import { assertSingleVal } from '../util/arrays';
import { TeamOutput } from '@bluepic/types/src/Auth/team.output';
import { StudioResources } from '@bluepic/types';

const jwt_storage_key = 'auth_jwt';

async function getProjectsOfUser(jwt: string) {
  const url = `https://studio-resources.c2.bluepic.io/api/files?${new URLSearchParams({
    type: '^application/json\\+bx$',
    tags: ['studio', 'project'].join(','),
    mode: 'read',
    project: '_id',
    limit: String(1),
  })}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response.json() as Promise<StudioResources.FilesOutput>;
}

type AuthStoreState = {
  hydrated: boolean;
  loggedOut: boolean;
  remember: boolean;
  jwt: string | null;
  user: UserOutput | null;
  permissions: string[];
  users: Map<string, UserOutput>;
  teams: TeamOutput[];
  projectsTotal: number | null;
  teamSwitchForbidden: boolean;
  customerinfo: string | null;
};

type AuthStoreActions = {
  init: (jwt: string, remember: boolean) => Promise<void>;
  tryFetchUser: (jwt?: string) => Promise<void>;
  tryFetchPermissions: () => Promise<void>;
  initFeed: () => void;
  refreshJWT: () => Promise<void>;
  getUser(id: string): Promise<UserOutput | undefined>;
  getUsers(ids: string[]): Promise<UserOutput[]>;
  hasPermission: (permission: string) => boolean;
  getCustomerInfo: () => Promise<string | undefined>;
} & BaseActions;

export const useAuthStore = defineStore<string, AuthStoreState, {}, AuthStoreActions>('auth', {
  state: () => ({
    hydrated: false as boolean,
    loggedOut: false as boolean,
    remember: false as boolean,
    jwt: null as string | null,
    user: null as UserOutput | null,
    teams: [],
    projectsTotal: null,
    permissions: [] as string[],
    users: new Map<string, UserOutput>(),
    teamSwitchForbidden: false as boolean,
    customerinfo: null as string | null,
  }),
  actions: {
    _hydrate() {
      try {
        let remember = false;
        let jwt = localStorage.getItem(jwt_storage_key);
        if (jwt) {
          remember = true;
        } else {
          jwt = sessionStorage.getItem(jwt_storage_key);
        }
        this.$patch({
          jwt,
          user: null,
          remember,
        });
      } catch (e) {
        console.error(e);
      }
      this.$subscribe((_, newState) => {
        try {
          if (newState.jwt === null) {
            localStorage.removeItem(jwt_storage_key);
            sessionStorage.removeItem(jwt_storage_key);
            return;
          }
          if (newState.remember) {
            localStorage.setItem(jwt_storage_key, newState.jwt);
          } else {
            sessionStorage.setItem(jwt_storage_key, newState.jwt);
          }
        } catch {}
      });
      this.hydrated = true;
      if (this.loggedOut) {
        if (this.jwt) {
          //
          killSession(undefined, this.jwt);
        }
        this.$reset();
        return;
      }
    },
    async init(jwt: string, remember: boolean) {
      this.remember = remember;
      this.jwt = String(jwt);

      // await Promise.allSettled([
      //   analytics(
      //     "auth",
      //     {
      //       service: "social",
      //       env: "web",
      //       action: "login",
      //     },
      //     jwt
      //   ),
      //   this.tryFetchUser(jwt),
      // ]);
    },
    async tryFetchUser(jwt?: string) {
      if (!this.hydrated) {
        this._hydrate?.();
      }
      if (this.user) {
        return;
      }
      if (!(jwt ?? this.jwt)) {
        return;
      }
      try {
        const user = await getUser((jwt ?? this.jwt)!);
        if (!user) {
          this.jwt = null;
          return;
        }
        this.user = user;
        this.users.set(user.id, user);
        this.jwt = jwt ?? this.jwt;

        const teams = await getTeamsOfUser(user.id, this.jwt!);
        this.teams = teams;

        const projects = await getProjectsOfUser(this.jwt!);
        this.projectsTotal = projects.total;

        await Promise.allSettled([await this.getCustomerInfo(), await this.tryFetchPermissions()]);
      } catch (e) {
        this.jwt = null;
      }
      this.initFeed();
    },
    async tryFetchPermissions() {
      if (!this.user || !this.jwt) return;
      try {
        this.permissions = (await getCurrentPermissions(this.user.id, this.jwt)) ?? [];
      } catch (e) {
        console.error(e);
      }
    },
    initFeed() {
      if (!this.user || !this.jwt) return;
      useFeed(this.jwt, ['auth2', 'cloud2'], ['USER', 'PROFILE_PICTURE'], (m) => {
        switch (m.service) {
          case 'auth2':
            switch (m.operation) {
              case 'UPDATE_USER':
                if (!m.resourceId || !m.payload) return;
                if (m.resourceId === this.user?.id) {
                  this.user = JSON.parse(m.payload);
                  if (this.user?.locked) {
                    return logout();
                  }
                  this.tryFetchPermissions();
                }
                this.users.set(m.resourceId, JSON.parse(m.payload));
                break;
              case 'DELETE_USER':
                if (!m.resourceId) return;
                if (m.resourceId === this.user?.id) {
                  return logout();
                }
                this.users.delete(m.resourceId);
                break;
            }
            break;
          case 'cloud2':
            switch (m.operation) {
              case 'SET_PROFILE_PICTURE':
                if (m.type === 'success' && m.resourceId && m.resourceId === this.user?.id) {
                  imgChange(m.resourceId);
                }
                break;
            }
            break;
        }
      });
    },
    async refreshJWT() {
      if (!this.jwt) return;
      try {
        this.jwt = await refreshCredentials(this.jwt);
      } catch (e) {
        this.jwt = null;
      }
    },
    async getUser(userId: string) {
      if (!userId || !this.jwt) return undefined;
      if (this.users.has(userId)) {
        return this.users.get(userId);
      }
      const result = await getUsers([userId], this.jwt);
      this.users.set(userId, result[0]);
      return result[0];
    },
    async getUsers(userIds: string[]) {
      if (!userIds.length || !this.jwt) return [];
      const unavailableUsers = userIds.filter((id) => !this.users.has(id));
      if (unavailableUsers.length) {
        const result = await getUsers(unavailableUsers, this.jwt);
        result.forEach((user) => this.users.set(user.id, user));
      }
      return userIds.map((id) => this.users.get(id)).filter((u): u is UserOutput => !!u);
    },
    hasPermission(permission: string) {
      if (!this.user || !this.permissions.length) return false;
      return this.permissions?.map((p: string) => p?.toUpperCase())?.includes(permission.toUpperCase());
    },
    async getCustomerInfo() {
      if (!this.user || !this.jwt) return;
      if (this.customerinfo !== null) {
        return this.customerinfo;
      }
      try {
        const md = assertSingleVal(await getMetadata({ key: 'customerInfo', userId: this.user.id }, this.jwt));
        this.customerinfo = md?.value ?? null;
        return this.customerinfo ?? undefined;
      } catch (e) {
        console.error(e);
      }
    },
  },
});

onLogout(() => {
  const authStore = useAuthStore(pinia);
  if (authStore.jwt) {
    try {
      killSession(undefined, authStore.jwt);
    } catch {}
  }
  authStore.$reset();
  authStore.loggedOut = true;
  router.push({
    path: '/onboarding/login',
    params: router.currentRoute.value.params,
    query: router.currentRoute.value.query,
    hash: router.currentRoute.value.hash,
  });
});
