import './util/monitoring';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/unbounded/300.css';
import '@fontsource/unbounded/400.css';
import '@fontsource/unbounded/500.css';
import '@fontsource/unbounded/600.css';
import '@fontsource/unbounded/700.css';
import { createApp } from 'vue';
import './assets/css/base.scss';
import App from './App.vue';
import './middleware/auth.guard';
import './middleware/transition';
import { router } from './routes';
import { pinia } from './stores/pinia';
import { i18n } from './util/i18n';
import { initHammer } from './util/hammer';
import posthog, { PostHog } from 'posthog-js';
import { CookieSettings } from '@bluepic/privacy';
// import { initIntercept } from "@bluepic/privacy";
// initIntercept(import.meta.env.V_CONSENT_LS_KEY);

const env = new Map<string, string>();
env.set('VERSION', APP_VERSION);
const omitInProd: (keyof ImportMetaEnv)[] = ['SSR', 'DEV', 'PROD'];
for (const key in import.meta.env) {
  if (import.meta.env.PROD && omitInProd.includes(key as keyof ImportMetaEnv)) {
    continue;
  }
  env.set(key, (import.meta.env as any)[key]);
}
// @ts-ignore
window.env = Object.fromEntries(env);
// @ts-ignore

export const app = createApp(App);

export const cookieSettings = ref<CookieSettings>();
let posthogInstance: PostHog | undefined = undefined;
watch(
  cookieSettings,
  () => {
    console.log('!!!!');

    if (cookieSettings.value?.analytics && !posthogInstance) {
      // Init posthog with consent
      posthogInstance = posthog.init('phc_N5wkwtrdqnadaxsOpYGtxgQJDvEGbV9QmPr5O3pkNkQ', {
        api_host: 'https://eu.i.posthog.com',
      });
      console.log('! posthog initialized !', posthogInstance);
    } else if (!cookieSettings.value?.analytics && posthogInstance) {
      // Disable posthog
      posthogInstance?.reset();
    }
  },
  { immediate: true, deep: true }
);

app.use(router);
app.use(i18n);
app.use(pinia);
initHammer(app);

app.mount('#app');
