{
  "email": "E-Mail Adresse",
  "password": "Passwort",
  "username": "Benutzername",
  "consent": {
    "agree": "Durch das registrieren stimmst du den",
    "terms": "Nutzungsbedingungen",
    "and": "zu und nimmst die",
    "privacy": "Datenschutzerklärung",
    "suffix": " zur Kenntnis"
  },
  "password-rating": {
    "weak": "schwach",
    "medium": "mittel",
    "strong": "stark",
    "tooWeak": "zu schwach"
  },
  "error": "Da ist etwas schief gelaufen...",
  "already-have-account": "Du hast schon einen Account?",
  "back-to-sign-in": "Zurück zur Anmeldung",
  "confirm-success": "Deine E-Mail Adresse wurde bestätigt. Jetzt kannst du dich einloggen",
  "confirming-email": "Deine E-Mail Adresse wird bestätigt...",
  "must-confirm-email": "Du musst deine E-Mail Adresse bestätigen, bevor du dich einloggen kannst",
  "consent-required": "Zustimmung wird benötigt",
  "do-sign-in": "Anmelden",
  "do-sign-up": "Registrieren",
  "dont-have-an-account": "Noch kein Konto?",
  "email-invalid": "E-Mail Adresse ist ungültig",
  "email-required": "E-Mail Adresse wird benötigt",
  "email-resent": "E-Mail nochmal verschickt",
  "forgot-password": "Passwort vergessen?",
  "forgot-text": "Gib deine E-Mail Adresse ein und wir schicken dir einen Link um dein Passwort zurückzusetzen.",
  "invalid-confirm-token": "Ungültiger Bestätigungslink. E-Mail Adresse wurde nicht gefunden oder wurde schon bestätigt.",
  "new-password": "Neues Passwort",
  "password-invalid": "Passwort entspricht nicht den Vorgaben",
  "password-required": "Passwort wird benötigt",
  "password-strength": "Passwort Stärke",
  "passwords-dont-match": "Passwörter stimmen nicht überein",
  "redirecting-to-sign-in": "Du wirst zur Anmeldung weiter geleitet...",
  "register-success": "Jetzt musst du nur noch deine E-Mail Adresse bestätigen. Schau in dein Postfach!",
  "remember-me": "Eingeloggt bleiben",
  "repeat-password": "Passwort wiederholen",
  "resend-email": "erneut senden",
  "reset-password": "Passwort zurücksetzen",
  "return-to-sign-in": "Zurück zum Anmelden",
  "send-email": "E-Mail verschicken",
  "send-sign-in-link": "Schick mir einen Anmeldungs-Link",
  "sign-in": "Anmelden",
  "sign-in-link": "Anmeldungs-Link",
  "sign-up": "Registrieren",
  "this-shouldnt-happen": "Das sollte nicht passieren... Bitte melde dich bei uns.",
  "admin": "Admin",
  "admin-only": "Nur Admins",
  "advanced": "mehr",
  "affiliate": "Affiliate",
  "afternoon": "Nachmittag",
  "api-keys": "API-Key",
  "appearance": "Allgemein",
  "are-you-absolutely-sure": "Bist du dir absolut sicher?",
  "are-you-sure-this-action-cannot-be-undone": "Bist du dir sicher? \nDiese Aktion kann nicht rückgängig gemacht werden",
  "are-you-sure-you-want-to-delete-this-metadata-entry": "Möchtest du diesen Metadaten-Eintrag wirklich löschen?",
  "are-you-sure-you-will-be-logged-out-on-all-other-devices": "Bist du dir sicher? \nDu wirst auf allen anderen Geräten abgemeldet",
  "are-you-sure-you-will-be-logged-out-on-this-device": "Bist du dir sicher? \nDu wirst auf diesem Gerät abgemeldet",
  "background": "Hintergrund",
  "background-1": "Hintergrund 1",
  "background-2": "Hintergrund 2",
  "background-gradient": "Hintergrundverlauf",
  "billing": "Abrechnung",
  "bluepic-admin": "Bluepic-Admin",
  "borders": "Ränder",
  "cancel": "Abbrechen",
  "change": "ändern",
  "change-picture": "Bild ändern",
  "click-or-drag-a-file-to-this-area-to-upload": "Klicke oder ziehe eine Datei in diesen Bereich, um sie hochzuladen",
  "close-all-other-sessions": "Alle anderen Sitzungen schließen",
  "collection-deleted": "Post gelöscht",
  "collection-saved": "Post gespeichert",
  "comment": "Kommentar",
  "confirm-delete-account": "Möchtest du dein Konto wirklich löschen? \nFür deine Privatsphäre ist dies dauerhaft und kann nicht rückgängig gemacht werden.",
  "confirm-delete-team": "Möchtest du dieses Team wirklich löschen? \nDiese Aktion kann nicht rückgängig gemacht werden.",
  "confirm-logout": "Möchtest du dich wirklich abmelden?",
  "confirm-permanently-delete-collection": "Möchtest du diesen Post wirklich dauerhaft löschen? \nDies kann nicht rückgängig gemacht werden!",
  "copied-to-clipboard": "In die Zwischenablage kopiert",
  "create": "Erstellen",
  "create-profile": "Profil erstellen",
  "create-sub-team": "Sub-Team erstellen",
  "create-team": "Team erstellen",
  "created-by-creator-name": "erstellt von {0}",
  "created-created": "erstellt: {0}",
  "delete": "Löschen",
  "delete-account": "Konto löschen",
  "delete-api-key": "API-Key löschen",
  "delete-collection": "Post löschen",
  "delete-metadata": "Metadaten löschen",
  "delete-team": "Team löschen",
  "delete-user": "Benutzer löschen",
  "demote": "Adminrechte entziehen",
  "digits": "Ziffern",
  "disabled": "Deaktiviert",
  "download": "Download",
  "edit": "Bearbeiten",
  "edit-templates": "Templates bearbeiten",
  "edit-theme": "Theme bearbeiten",
  "editor": "Editor",
  "editor-warning.confirm": "OK ich verstehe",
  "editor-warning.content": "Dies ist eine frühe Beta-Version. \nWir empfehlen dringend, Google Chrome zu verwenden, um sicherzustellen, dass alles so aussieht und funktioniert, wie du es erwartest.",
  "editor-warning.title": "Betaversion!",
  "email-is-required": "E-Mail ist erforderlich",
  "email-sent": "E-Mail gesendet",
  "error-0": "Error",
  "evening": "Abend",
  "expires": "läuft ab: {0}",
  "failed-invites": "Fehlgeschlagene Einladungen:",
  "failed-to-update-user": "Benutzer konnte nicht aktualisiert werden",
  "feed": "Feed",
  "feed-type.they.COLLECTION_CREATED": "einen Beitrag erstellt",
  "feed-type.they.COLLECTION_DELETED": "einen Beitrag gelöscht",
  "feed-type.they.COLLECTION_UPDATED": "einen Beitrag aktualisiert",
  "feed-type.they.COMMENT": "kommentiert",
  "feed-type.you.COLLECTION_CREATED": "einen Beitrag erstellt",
  "feed-type.you.COLLECTION_DELETED": "einen Beitrag gelöscht",
  "feed-type.you.COLLECTION_UPDATED": "einen Beitrag aktualisiert",
  "feed-type.you.COMMENT": "kommentiert",
  "field-not-found": "Feld nicht gefunden",
  "finish": "Fertig",
  "font": "Schriftart",
  "font-size": "Schriftgröße",
  "foreground-1": "Vordergrund 1",
  "foreground-2": "Vordergrund 2",
  "gallery": "Galerie",
  "get-invite": "Einladung erhalten",
  "greyscale-background": "Graustufen-Hintergrund",
  "im-sure": "Ich bin sicher",
  "incoming-invites": "Eingehende Einladungen:",
  "invalid-magic-link": "ungültiger Anmeldelink",
  "invite-accepted": "Einladung angenommen!",
  "invite-declined": "Einladung abgelehnt!",
  "invite-redeemed": "Einladung eingelöst",
  "invite-resent": "Einladung erneut senden",
  "invite-revoked": "Einladung widerrufen",
  "invite-sent": "Einladung gesendet",
  "invite-sent-successfully": "Einladung erfolgreich gesendet",
  "invite-user": "Benutzer einladen",
  "invite-will-be-sent": "Einladung wird verschickt",
  "invited-by-inviter-name": "eingeladen von {0}",
  "invites": "Einladungen",
  "invites-could-not-be-sent": "Einladungen konnten nicht gesendet werden",
  "invites-sent-successfully": "Einladungen erfolgreich gesendet",
  "ip-session-ip": "IP-Adresse: {0}",
  "key": "Key",
  "key-is-required": "Key ist erforderlich",
  "kill-all-sessions": "Beende alle Sitzungen",
  "kill-session": "Sitzung schließen",
  "language": "Sprache",
  "last-chance-to-keep-account": "Dies ist deine letzte Chance, abzubrechen. \nSobald du dein Konto löschst, ist es für immer weg.",
  "last-edit-lastedit": "letzte Änderung: {0}",
  "last-seen-lastseen": "Zuletzt gesehen: {0}",
  "leave": "Verlassen",
  "leave-team": "Team verlassen",
  "left-team": "Team verlassen",
  "log-out": "Abmelden",
  "lower-case": "Kleinbuchstaben",
  "magic-link-sent": "Wir haben dir einen Link geschickt, der dich automatisch anmeldet. Überprüfe dein Postfach",
  "max-16mb-allowed-png-jpg-jpeg": "max. \n20 MB, erlaubt: .png .jpg .jpeg",
  "me": "Ich",
  "metadata": "Metadaten",
  "metadata-created": "Metadaten erstellt",
  "metadata-deleted": "Metadaten gelöscht",
  "min-8-characters": "Mindest. \n8 Zeichen",
  "morning": "Morgen",
  "my-teams": "Meine Teams",
  "my-templates": "Meine Templates",
  "name": "Name",
  "name-cannot-contain-spaces": "Name darf keine Leerzeichen enthalten",
  "name-is-required": "Name ist erforderlich",
  "night": "Nacht",
  "no-category": "Keine Kategorie",
  "no-field-for-datatype-type-properties-modelvalue": "Kein Feld für Datentyp \"{0}\" {1} {2}",
  "none": "Keins",
  "notifications": "Benachrichtigungen",
  "offboarding": "Vielen Dank, dass du unseren Service genutzt hast. \nAlle deine Daten wurden dauerhaft gelöscht. \nDu wirst jetzt abgemeldet...",
  "only-png-and-jpeg-files-are-allowed": "Es sind nur PNG- und JPEG-Dateien zulässig",
  "only-redeemed": "Nur eingelöste",
  "parent": "Parent",
  "pending-invites": "Ausstehende Einladungen:",
  "preview": "Vorschau",
  "primary": "Primär",
  "profile-picture": "Profilbild",
  "profile-picture-updated": "Profilbild aktualisiert",
  "profile-updated-successfully": "Profil erfolgreich aktualisiert",
  "promote": "Zu Admin machen",
  "remove": "Entfernen",
  "resend": "Erneut senden",
  "resend-confirmation-email": "Bestätigungsmail erneut senden",
  "reset": "Zurücksetzen",
  "reset-view": "Ansicht zurücksetzen",
  "retry": "Wiederholen",
  "revoke": "Widerrufen",
  "roundness": "Rundheit",
  "save": "Speichern",
  "save-to-collection": "In Post speichern",
  "saved": "Gespeichert",
  "search": "Suche",
  "secondary": "Sekundär",
  "select-a-workspace": "Wähle einen Space aus",
  "select-team": "Team auswählen",
  "select-variant": "Variante wählen:",
  "sessions": "Sitzungen",
  "set-metadata": "Metadaten festlegen",
  "settings": "Einstellungen",
  "share": "Teilen",
  "share-with-sub-teams": "mit Sub-Teams teilen",
  "signing-in": "Einloggen",
  "space": "Space",
  "spaces": "Spaces",
  "special-characters": "Sonderzeichen",
  "standalone-link": "Direkter Link:",
  "success": "Erfolg",
  "tap-here-to-upload": "Tippe hier, um hochzuladen",
  "team-created": "Team erstellt",
  "team-deleted": "Team gelöscht",
  "teams": "Teams",
  "templates": "Templates",
  "text": "Text",
  "theme": "Theme",
  "title": "Titel",
  "title-is-required": "Titel ist erforderlich",
  "upload": "Hochladen",
  "upper-case": "Großbuchstaben",
  "uses-apikey": "verwendet: {0}{1}",
  "value": "Wert",
  "value-is-required": "Wert erforderlich",
  "warning": "Warnung",
  "workspace-is-required": "Space ist erforderlich",
  "yes": "Ja",
  "you-can-close-this-tab": "Du kannst diesen Tab jetzt schließen",
  "you-cant-leave-if-youre-the-only-admin": "Du kannst das Team nicht verlassen, wenn du der einzige Administrator bist",
  "your-teams": "Deine Teams:",
  "zoom": "Zoomen",
  "zoom-and-crop": "Zoomen und Croppen",
  "errors.ERR_AUTH_65": "ungültige Sitzung",
  "content": "Content",
  "fontsize": {
    "small": "klein",
    "medium": "mittel",
    "large": "groß"
  },
  "livemode": {
    "auto": "Auto",
    "always": "Immer",
    "never": "Nie"
  },
  "editor-live-preview": "Live-Vorschau im Editor",
  "live-preview-help": "Änderungen im Editor werden sofort angezeigt. Kann auf manchen Geräten zu Performance-Problemen führen.",
  "rendering": "wird gerendert",
  "drafts": "Entwürfe",
  "s-space": "'s Space",
  "draft": "Entwurf",
  "found-drafts": "Entwürfe:",
  "start-fresh": "ohne Entwurf fortfahren",
  "only-unredeemed": "Nur uneingelöst",
  "redeemed-invites": "Eingelöste Einladungen:",
  "you": "Sie",
  "email-confirmed": "E-Mail bestätigt",
  "email-not-confirmed": "E-Mail noch nicht bestätigt",
  "organization": "Organisation",
  "organization-shorthand": "Org",
  "email-template": "E-Mail-Vorlage",
  "add-payment-method": "Zahlungsmethode hinzufügen",
  "address-line-2": "Adresszeile 2",
  "are-you-sure-you-want-to-cancel-this-subscription": "Möchtest du dieses Abonnement wirklich kündigen?",
  "are-you-sure-you-want-to-delete-this-payment-method": "Möchtest du diese Zahlungsmethode wirklich löschen?",
  "billing-contact": "Rechnungskontakt",
  "cancel-subscription": "Abonnement kündigen",
  "city": "Stadt",
  "confirm": "Bestätigen",
  "current-plan": "Aktueller Plan",
  "default": "Standard",
  "delete-payment-method": "Zahlungsmethode löschen",
  "enterprise": "Unternehmen",
  "free": "Free",
  "invoices": "Rechnungen",
  "load-more": "Mehr laden",
  "monthly": "Monatlich",
  "no-invoices-found": "Keine Rechnungen gefunden",
  "no-payment-methods-found": "Keine Zahlungsmethoden gefunden",
  "open": "Offen",
  "paid": "Bezahlt",
  "pay": "Zahlen",
  "payment-methods": "Zahlungsarten",
  "per": "pro",
  "phone": "Telefon",
  "pro": "Pro",
  "state": "Bundesland",
  "street": "Adresszeile 1",
  "subscriptions": "Abonnements",
  "switch-to-monthly-billing": "Wechseln zur monatlichen Abrechnung",
  "switch-to-yearly-billing": "Wechseln zur jährlichen Abrechnung",
  "uncollectible": "Buchung abgelehnt",
  "void": "Ungültig",
  "yearly": "Jährlich",
  "zip": "Postleitzahl",
  "expiration-date": "Ablaufdatum",
  "month": "Monat",
  "year": "Jahr",
  "address-line-1-is-required": "Adresszeile 1 ist erforderlich",
  "city-is-required": "Stadt ist erforderlich",
  "fast": "schnell",
  "phone-is-required": "Telefon ist erforderlich",
  "postal-code-is-required": "Postleitzahl ist erforderlich",
  "slow": "langsam",
  "state-is-required": "Staat ist erforderlich",
  "transition-speed": "Übergangsgeschwindigkeit",
  "are-you-in-the-right-team": "Bist du im richtigen Team?",
  "you-dont-have-permission-for-this-template": "Du darfst dieses Template nicht benutzen.",
  "copy-link": "Link kopieren",
  "accept": "Annehmen",
  "by": "von",
  "decline": "Ablehnen",
  "invalid-invite": "Ungültige Einladung",
  "you-are-not-the-intended-recipient-of-this-invite": "Du bist nicht der beabsichtigte Empfänger dieser Einladung.",
  "you-have-been-invited-to-join": "Du wurdest eingeladen einem Team beizutreten:",
  "pro-badge": "PRO",
  "next-payment": "Nächste Zahlung",
  "cancel-subscription-short": "kündigen",
  "keep-it": "behalten",
  "subscription-canceled": "Abonnement gekündigt",
  "5-personal-templates": "5 private Templates",
  "early-access-to-new-template-collections": "früher Zugriff auf neue Template-Kollektionen",
  "free-forever": "Für immer kostenlos",
  "full-access-to-learning-material": "vollen Zugriff auf Lernmaterial",
  "pro-header": "Pro - 5€ / Monat",
  "pro-subtitle": "Für unabhängige Content-Creator",
  "professional": "Pro",
  "switch-to-free": "Wechseln zu Free",
  "switch-to-pro": "Wechseln zu Pro",
  "unlimited-personal-templates": "unbegrenzte private Templates",
  "unlimited-projects": "unbegrenzte Projekte",
  "cancel-pro-subscription": "Möchtest du dein Pro-Abonnement wirklich kündigen? \nDu verlierst den Zugriff auf alle Pro-Funktionen. \nDeine überzähligen privaten Templates werden gelöscht.",
  "running-out": "Läuft aus",
  "reactivate": "Reaktivieren",
  "delete-all": "alle löschen",
  "are-you-sure-you-want-to-delete-all-drafts-this-cannot-be-undone": "Bist du sicher, dass du alle Entwürfe löschen möchtest? Das kann nicht rückgängig gemacht werden!",
  "vat": "UST",
  "apply": "Anwenden",
  "confirm-purchase": "Kauf bestätigen",
  "coupon": "Coupon",
  "renews-automatically": "wird automatisch verlängert",
  "subtotal": "Summe",
  "total": "Rechnungsbetrag",
  "shared": "geteilt",
  "all-subscriptions": "Alle Abonnements",
  "deleted-user": "gelöschter Nutzer",
  "upcoming-payments": "anstehende Zahlungen",
  "hidden": "Versteckt",
  "visible": "Sichtbar",
  "failed-to-update-team": "Team konnte nicht aktualisiert werden",
  "mixed": "gemischt",
  "enabled": "aktiviert",
  "extra": "Extra",
  "medium": "Mittel",
  "minimal": "Minimal",
  "coupon-applied": "Gutschein angewendet",
  "active": "Aktiv",
  "make-default": "Zur Standard-Zahlungsmethode machen",
  "no-subscription": "Kein Abo",
  "owner": "Besitzer",
  "sub_inactive": "Abonnement ist inaktiv",
  "sub_noPayment": "Dieses Abonnement wurde nicht bezahlt",
  "sub_noSubscription": "Wir konnten für dieses Team kein Abonnement finden",
  "sub_notEnoughDepth": "Dein Team hat mehr Subteam-Ebenen, als dein Abonnement zulässt",
  "sub_notEnoughSlots": "Dein Team hat mehr Mitglieder, als dein Abonnement zulässt",
  "action-required": "Handlung erforderlich",
  "hierarchy-layers": "Subteam-Ebenen",
  "pay-now": "Jetzt bezahlen",
  "slots": "Slots",
  "subscribe-now": "Abonniere jetzt",
  "subscription": "Abonnement",
  "upgrade-subscription-now": "Abonnement jetzt upgraden",
  "excl": "excl",
  "incl": "incl",
  "incl-tax": "incl. Ust",
  "tax": "Ust",
  "payment-interval": "Zahlungsintervall",
  "hard-delete-team": "Team HART-löschen",
  "edit-subscription": "Abonnement bearbeiten",
  "hierarchy-layers-are-required": "Subteam-Ebenen sind erforderlich",
  "maximum-1-layer": "Maximal 1 Ebene",
  "maximum-300-slots": "Maximal 300 Steckplätze",
  "minimum-status-value-actualdepth-1-layer": "Mindestens {0} Ebenen",
  "minimum-status-value-totalmembers-5-slots": "Mindestens {0} Slots",
  "slots-are-required": "Steckplätze sind erforderlich",
  "you-need-to-add-a-payment-method-before-you-can-purchase-a-subscription": "Du musst eine Zahlungsmethode hinzufügen, bevor du ein Abonnement abschließen kannst.",
  "all-teams": "Alle Teams",
  "all-users": "Alle User",
  "custom-prices": "Custom Preise",
  "admin_prices": "Custom Preise",
  "admin_teams": "Alle Teams",
  "admin_users": "Alle User",
  "no-custom-prices-found": "Keine custom Preise gefunden",
  "create-custom-price": "Custom Preis erstellen",
  "choose-your-own-price": "Was möchtest du bezahlen?",
  "custom-price": "Custom Preis",
  "expression": "Formel",
  "expression-is-required": "Formel wird benötigt",
  "invalid-expression": "Ungültige Formel",
  "javascript-expression": "Javascript Ausdruck",
  "min-newprice": "min. {0}",
  "no-subscription-needed": "Kein Abonnement nötig",
  "price": "Preis",
  "search-users-by-email": "Suche User nach email",
  "select": " ",
  "try-it-out": "Tester",
  "update": "Update",
  "user-ids": "User Ids",
  "users": "Users",
  "admin-settings": "Admin Einstellungen",
  "experimental": "Experimental-",
  "use-webgl-when-available": "WebGL nutzen, wenn verfügbar",
  "uploading": "Hochladen",
  "normalizing": "Normalisieren",
  "checking-permission": "Erlaubnis prüfen",
  "upload-failed": "Hochladen fehlgeschlagen",
  "image-file-size-is-too-big": "Bilddatei ist zu groß.",
  "please-let-us-know-if-this-keeps-happening": "Bitte sag uns Bescheid, falls dies weiterhin auftritt.",
  "image-file-is-corrupted": "Bilddatei ist beschädigt.",
  "learn-to-use-bluepic-social": "Lerne Bluepic Social kennen",
  "onboarding": "Onboarding",
  "reset-all": "Alles zurücksetzen",
  "reset-progress": "Fortschritt zurücksetzen",
  "resume": "Fortsetzen",
  "start": "Start",
  "admin_feature_flags": "Feature-Flags",
  "are-you-sure-you-want-to-reset-all-progress": "Bist du sicher, dass du den gesamten Fortschritt zurücksetzen möchtest?",
  "feature-flags": "Feature-Flags",
  "nothing-to-see-here": "Hier gibt's nichts zu sehen",
  "reset-all-progress": "Gesamten Fortschritt zurücksetzen",
  "add-connection": "Verknüfung hinzufügen",
  "are-you-sure-you-want-to-remove-this-connection": "Möchtest du diese Verknüfung wirklich entfernen?",
  "connected-accounts": "Verknüpfte Konten",
  "connected-accounts-num-0": "Keine Verknüfung",
  "connected-accounts-num-1": "eine Verknüfung",
  "connected-accounts-num-x": "{0} Verknüfungen",
  "continue-with": "Weiter mit:",
  "or": "oder",
  "rate-limit-exceeded-please-try-again-in-a-few-minutes": "Rate-Limit überschritten. \nBitte versuchs in ein paar Minuten erneut.",
  "remove-connection": "Verbindung entfernen",
  "unauthorized": "Nicht autorisiert",
  "errors.ERR_AUTH_1": "Du bist nicht berechtigt, einen API-Schlüssel für diesen Benutzer zu erstellen",
  "errors.ERR_AUTH_10": "Benutzer nicht gefunden",
  "errors.ERR_AUTH_100": "Der Benutzer ist kein Teambesitzer",
  "errors.ERR_AUTH_101": "Preis konnte nicht generiert werden",
  "errors.ERR_AUTH_102": "Abonnement konnte nicht erstellt werden",
  "errors.ERR_AUTH_103": "Ungültiger benutzerdefinierter Preisausdruck",
  "errors.ERR_AUTH_104": "Der Benutzer ist bereits Mitglied dieses Teams",
  "errors.ERR_AUTH_105": "Du kannst Metadaten nicht sowohl für Benutzer als auch für Teams festlegen",
  "errors.ERR_AUTH_106": "Unbekannter Emittent",
  "errors.ERR_AUTH_107": "Nicht implementiert",
  "errors.ERR_AUTH_108": "Du darfst kein Team ohne Abonnement erstellen",
  "errors.ERR_AUTH_109": "Du darfst dieses Team nicht endgültig löschen",
  "errors.ERR_AUTH_11": "Ungültiger Schlüssel",
  "errors.ERR_AUTH_110": "Du bist nicht berechtigt, dieses Teams-Abonnement zu bearbeiten",
  "errors.ERR_AUTH_111": "Du bist nicht berechtigt, dieses Teams-Abonnement zu überprüfen",
  "errors.ERR_AUTH_112": "Nur Root-Teams können Abonnements haben",
  "errors.ERR_AUTH_113": "Kein Token",
  "errors.ERR_AUTH_114": "Keine E-Mail im Token",
  "errors.ERR_AUTH_115": "Ungültiges OIDC-ID-Token",
  "errors.ERR_AUTH_116": "E-Mail ist für Nicht-Dummy-Benutzer erforderlich",
  "errors.ERR_AUTH_117": "Dieser Benutzer ist nicht Teil dieses Teams",
  "errors.ERR_AUTH_118": "keine E-Mail",
  "errors.ERR_AUTH_119": "ID ist bereits mit einem Konto verknüpft",
  "errors.ERR_AUTH_12": "Du hast zu lange gebraucht, um den Code einzugeben.",
  "errors.ERR_AUTH_120": "ID nicht gefunden",
  "errors.ERR_AUTH_13": "E-Mail-Bestätigung nicht gefunden",
  "errors.ERR_AUTH_14": "E-Mail-Bestätigung abgelaufen",
  "errors.ERR_AUTH_15": "Fehler beim Bestätigen der E-Mail",
  "errors.ERR_AUTH_16": "Der Benutzer darf dieses Team nicht einladen",
  "errors.ERR_AUTH_17": "Der Benutzer darf diese Einladung nicht einlösen",
  "errors.ERR_AUTH_18": "Einladung nicht gefunden",
  "errors.ERR_AUTH_19": "Benutzer darf Einladungen nicht lesen",
  "errors.ERR_AUTH_2": "Der Apikey-Ablauf darf nicht mehr als 1 Jahr in der Zukunft liegen",
  "errors.ERR_AUTH_20": "Der Benutzer darf diese Einladung nicht widerrufen",
  "errors.ERR_AUTH_21": "Der Benutzer darf diese Einladung nicht ablehnen",
  "errors.ERR_AUTH_22": "Du bist nicht berechtigt, Metadaten für diesen Benutzer abzurufen",
  "errors.ERR_AUTH_23": "Du bist nicht berechtigt, Metadaten für diesen Benutzer festzulegen",
  "errors.ERR_AUTH_24": "Metadaten nicht gefunden",
  "errors.ERR_AUTH_25": "Du bist nicht berechtigt, die Berechtigungen dieses Benutzers zu erhalten",
  "errors.ERR_AUTH_26": "Zurücksetzen nicht gefunden",
  "errors.ERR_AUTH_27": "Zurücksetzen abgelaufen",
  "errors.ERR_AUTH_28": "Du kannst keine andere Benutzersitzung löschen",
  "errors.ERR_AUTH_29": "Du kannst die Sitzungen dieses Benutzers nicht anzeigen",
  "errors.ERR_AUTH_3": "Du bist nicht berechtigt, Mehrzweck-Apikeys zu erstellen",
  "errors.ERR_AUTH_30": "Du darfst kein Team erstellen",
  "errors.ERR_AUTH_31": "Du bist nicht berechtigt, ein Unterteam für dieses Team zu erstellen",
  "errors.ERR_AUTH_33": "Der Teamname existiert bereits in deiner Organisation",
  "errors.ERR_AUTH_35": "Du darfst nicht alle Teams bekommen",
  "errors.ERR_AUTH_36": "Team nicht gefunden",
  "errors.ERR_AUTH_37": "Benutzer ist bereits Administrator",
  "errors.ERR_AUTH_38": "Du kannst diesem Team keine Administratoren hinzufügen",
  "errors.ERR_AUTH_39": "Benutzer ist kein Administrator",
  "errors.ERR_AUTH_4": "Du bist nicht berechtigt, einen magischen Link für diesen Benutzer zu erstellen",
  "errors.ERR_AUTH_40": "Du kannst den letzten Administrator nicht entfernen",
  "errors.ERR_AUTH_41": "Du kannst keine Administratoren aus diesem Team entfernen",
  "errors.ERR_AUTH_42": "Benutzer ist bereits Mitglied",
  "errors.ERR_AUTH_43": "Benutzer ist kein Mitglied",
  "errors.ERR_AUTH_44": "Du kannst keine Mitglieder aus diesem Team entfernen",
  "errors.ERR_AUTH_45": "Benutzer ist ein Administrator",
  "errors.ERR_AUTH_46": "Du darfst diesen Benutzer nicht sperren",
  "errors.ERR_AUTH_47": "Team ist nicht gesperrt",
  "errors.ERR_AUTH_48": "Du bist nicht berechtigt, dieses Team zu bearbeiten",
  "errors.ERR_AUTH_49": "Team mit diesem Namen existiert bereits",
  "errors.ERR_AUTH_5": "Apikey nicht gefunden",
  "errors.ERR_AUTH_50": "Du bist nicht berechtigt, Teammitglieder zu bearbeiten",
  "errors.ERR_AUTH_51": "Du bist nicht berechtigt, Teamthemen zu bearbeiten",
  "errors.ERR_AUTH_52": "Du bist nicht berechtigt, die Passthrough-Berechtigungen dieses Teams zu ändern",
  "errors.ERR_AUTH_53": "Du kannst nur Berechtigungen weitergeben, die bereits im Team vorhanden sind",
  "errors.ERR_AUTH_54": "Du bist nicht berechtigt, die Berechtigungen dieses Teams zu ändern",
  "errors.ERR_AUTH_55": "Du bist nicht berechtigt, dieses Team zu löschen",
  "errors.ERR_AUTH_56": "Du kannst ein Team mit Subteams nicht löschen",
  "errors.ERR_AUTH_57": "Dummy-Benutzer können TOTP nicht aktiviert haben",
  "errors.ERR_AUTH_58": "Ein Benutzer mit dieser E-Mail existiert bereits",
  "errors.ERR_AUTH_59": "Einem Team muss ein Dummy-Benutzer zugewiesen werden",
  "errors.ERR_AUTH_6": "Du bist nicht berechtigt, die API-Schlüssel dieses Benutzers anzuzeigen",
  "errors.ERR_AUTH_60": "Passwörter müssen mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten",
  "errors.ERR_AUTH_61": "Du kannst nur Dummy-Benutzer für dein eigenes Team erstellen",
  "errors.ERR_AUTH_62": "Du bist nicht berechtigt, diesen Benutzer zu entsperren",
  "errors.ERR_AUTH_63": "Du bist nicht berechtigt, diesen Benutzer zu entfernen",
  "errors.ERR_AUTH_64": "keine Benutzer gefunden",
  "errors.ERR_AUTH_67": "Dummy-Benutzer können 2fa nicht verwenden",
  "errors.ERR_AUTH_68": "kein neues Topp-Geheimnis zu bestätigen",
  "errors.ERR_AUTH_69": "ungültiges Token",
  "errors.ERR_AUTH_7": "Du bist nicht berechtigt, diesen API-Schlüssel zu löschen",
  "errors.ERR_AUTH_70": "Du bist nicht berechtigt, diesen Benutzer zu bearbeiten",
  "errors.ERR_AUTH_72": "Du bist nicht berechtigt, die maximale Sitzungsanzahl zu bearbeiten",
  "errors.ERR_AUTH_73": "Du bist nicht berechtigt, die Berechtigungen dieses Benutzers zu ändern",
  "errors.ERR_AUTH_74": "Du bist nicht berechtigt, das Passwort dieses Benutzers zu ändern",
  "errors.ERR_AUTH_76": "Keine neue E-Mail zur Bestätigung",
  "errors.ERR_AUTH_79": "Wert erforderlich",
  "errors.ERR_AUTH_80": "Apikey abgelaufen",
  "errors.ERR_AUTH_81": "Admins und Mitglieder dürfen sich nicht überschneiden",
  "errors.ERR_AUTH_82": "Profil ist deaktiviert",
  "errors.ERR_AUTH_83": "Ungültiger Schlüssel",
  "errors.ERR_AUTH_84": "Während der geschlossenen Beta ist eine Einladung erforderlich",
  "errors.ERR_AUTH_85": "Der Benutzer ist kein Affiliate",
  "errors.ERR_AUTH_86": "Profil ist deaktiviert",
  "errors.ERR_AUTH_87": "Kein Auth-Header bereitgestellt",
  "errors.ERR_AUTH_88": "Ungültiger Auth-Header",
  "errors.ERR_AUTH_89": "Ungültiger API-Schlüssel",
  "errors.ERR_AUTH_9": "Benutzer ist gesperrt. \nÜberprüfe deine E-Mails.",
  "errors.ERR_AUTH_90": "Ungültige Sitzung",
  "errors.ERR_AUTH_92": "Ungültiger OIDC Token",
  "errors.ERR_AUTH_93": "Benutzerdefinierter Preis nicht gefunden",
  "errors.ERR_AUTH_94": "Benutzerdefinierter Preis wird von Teams verwendet",
  "errors.ERR_AUTH_95": "Abonnement nicht gefunden",
  "errors.ERR_AUTH_96": "Kunde nicht gefunden",
  "errors.ERR_AUTH_97": "Wähle deinen eigenen Preis nicht erlaubt",
  "errors.ERR_AUTH_98": "Team nicht gefunden",
  "errors.ERR_AUTH_99": "Subteams können keine eigenen Abonnements haben",
  "login-successful": "Anmeldung erfolgreich",
  "you-can-close-this-window-now": "Du kannst dieses Fenster jetzt schließen",
  "contact-us": "Kontaktiere uns",
  "invalid-email-or-password": "Ungültige E-Mail oder Passwort",
  "login-failed": "Fehler bei der Anmeldung",
  "country": "Land",
  "country-is-required": "Land ist erforderlich",
  "edit-billing-contact": "Rechnungskontakt bearbeiten",
  "incomplete": "Unvollständig",
  "please-complete-your-billing-information": "Bitte vervollständige deinen Rechnungskontakt",
  "reactivate-subscription": "Abo reaktivieren",
  "select-profile": "Profil wählen",
  "sub_canceled": "Abo gekündigt",
  "you-cant-leave-if-youre-the-owner": "Du kannst das Team nicht verlassen wenn du der Besitzer bist",
  "please-wait": "bitte warten",
  "credit": "Guthaben",
  "send-invite": "Einladung schicken",
  "contrast-between-primary-and-background-is-low": "Kontrast zwischen Primär und Hintergrund ist gering!",
  "reset-to-default": "Auf Standart zurücksetzen",
  "failed-to-load-image": "Bild konnte nicht geladen werden",
  "sign-out": "Abmelden",
  "balance": "Guthaben",
  "email-not-verified": "E-Mail nicht verifiziert",
  "manage-billing": "Abrechnung verwalten",
  "browser": "Browser",
  "device-model": "Gerät",
  "you-can-only-upload-files-up-to-10mb": "Du kannst nur Dateien bis zu 20 MB hochladen",
  "compressing": "Komprimieren",
  "cropping": "Zuschneiden",
  "saving": "Speichern",
  "something-went-wrong": "Da ist etwas schief gegangen...",
  "no-returnurl-specified": "Keine returnurl angegeben",
  "redirecting-to-target-href": "Weiterleiten zu {0} ...",
  "home": "Startseite",
  "user-settings": "Einstellungen",
  "api": "API",
  "api-description": "Bluepic API",
  "client-apps": "Client Apps",
  "client-apps-description": "Erstelle und verwalte deine Client Apps um API Zugriff zu ermöglichen",
  "app-headline-social": "Fülle deine Vorlagen mit Inhalt in SOCIAL",
  "app-headline-studio": "Erstelle Grafikvorlagen für deine Marke in STUDIO",
  "app-headline-embed": "Eingebetteter Grafik-Generator auf deiner Website oder App mit EMBED",
  "apps": "Apps",
  "recommended": "empfohlen",
  "welcome-to-bluepic": "Willkommen bei Bluepic",
  "selected-tutorials": "Ausgewählte Videos",
  "useful-links": "Nützliche Links",
  "support": "Support",
  "tutorials-documentation": "Anleitungen / Dokumentation",
  "do-open": "Öffnen",
  "SPACE_EVENTS": "etwas in meinem Space passiert (Mitglieder hinzugefügt/entfernt, Besitzer wechselt, etc.)",
  "TEMPLATES": "meine Space Templates sich ändern",
  "INVITES": "Ich zu einem Space eingeladen werde",
  "ACCOUNT_EVENTS": "mein Account sich ändert (Passwort, E-Mail, etc.)",
  "notify-me-when": "Benachrichtige mich, wenn",
  "settings-saved": "Einstellungen gespeichert!",
  "via": "per",
  "app": "App",
  "email-0": "E-Mail",
  "onboarding-title": "Willkommen bei Bluepic",
  "onboarding-subtitle": "Erstelle smarte Grafik-Vorlagen",
  "cinfo-step1-p2": "Sag uns bitte deinen Namen damit wir wissen, wie wir dich ansprechen sollen.",
  "cinfo-step2-p1": "Herzlich Wilkommen",
  "cinfo-step2-p2": "Nenn uns bitte den Namen deiner Firma und eure Website, damit wir deinen Workspace anlegen können.",
  "cinfo-step3-p1": "Bevor du loslegst...",
  "cinfo-step3-p2": "Bitte sag uns, wie du von Bluepic erfahren hast. Damit hilfst du uns enorm weiter 🙏",
  "wo-hast-du-von-bluepic-erfahren": "Wo hast du von Bluepic erfahren?",
  "continue": "Weiter",
  "done": "Fertig",
  "company-name": "Firmenname",
  "company-website": "Website",
  "ref-ad": "Werbung",
  "ref-embed": "Eingebettete Vorlage auf einer anderen Website",
  "ref-other": "Andere",
  "ref-recommendation": "Empfehlung",
  "ref-search": "Suchmaschine",
  "ref-social": "Sozialen Medien",
  "learn-bluepic": "Learn Bluepic",
  "learn-bluepic-description": "Anleitungen, Video Tutorials und mehr",
  "home-headline": "Beginne jetzt mit der Erstellung deiner smarten Grafiken",
  "app-cta-studio": "Gestalte ein Vorlage",
  "app-cta-embed": "Erzeuge ein Widget",
  "app-cta-social": "Plattform für Teams",
  "app-tooltip-studio": "Gestalte responsive Design-Vorlagen. Verknüpfe die Vorlage mit einem Formular, um eine einfache Bearbeitung zu ermöglichen. Veröffentliche die Vorlage, um ein Widget zu erstellen oder sie einer Team-Galerie hinzuzufügen.",
  "app-tooltip-social": "Erstelle eine Galerie mit ausgewählten Vorlagen und teile sie mit deinem Team. Arbeite gemeinsam mit deinem Team und nutze die Vorlagen, um Entwürfe zu erstellen und herunterzuladen. Verwende den Batch-Editor, um Daten in viele Entwürfe gleichzeitig umzuwandeln.",
  "app-tooltip-embed": "Wähle eine deiner Vorlagen aus, um ein öffentlich teilbares Widget zu erstellen. Generiere einen Code-Schnipsel, um das Widget auf deiner Website einzubetten. Erstelle eine eigene URL, um dein Widget direkt zu teilen. Passe das Aussehen und Verhalten deines Widgets individuell an.",
  "back": "Zurück",
  "skip": "Überspringen",
  "onboarding-progress": "Onboarding Fortschritt",
  "redirecting": "Du wirst weiter geleitet...",
  "resend-confirmation-success": "Bestätigungsmail erneut gesendet",
  "thanks-for-signup": "Danke fürs Anmelden!",
  "appsumo-license-claimed-successfully": "AppSumo Lizenz erfolgreich verbunden",
  "claim": "Verbinden",
  "wrong-account": "Falscher Account?",
  "your-appsumo-lifetime-purchase-is-valid-and-ready-for-you": "Deine AppSumo Lifetime-Lizenz ist geprüft und bereit! 🥳",
  "APPSUMO_API": "AppSumo API Credits",
  "APPSUMO_EMBED": "AppSumo EMBED Credits",
  "APPSUMO_SOCIAL": "AppSumo SOCIAL Credits",
  "DAILY": "Täglich",
  "MONTHLY": "Monatlich",
  "WEEKLY": "Wöchentlich",
  "YEARLY": "Jährlich"
}
